/* Fonts */

/* Proxima Nova */
@font-face {
    font-family: "proxima-nova";
    src: url("fonts/proxima-nova/proxima-nova-regular.woff2") format("woff2")
        url("fonts/proxima-nova/proxima-nova-regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "proxima-nova";
    src: url("fonts/proxima-nova/proxima-nova-italic.woff2") format("woff2"),
        url("fonts/proxima-nova/proxima-nova-italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "proxima-nova";
    src: url("fonts/proxima-nova/proxima-nova-light.woff2") format("woff2"),
        url("fonts/proxima-nova/proxima-nova-light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "proxima-nova";
    src: url("fonts/proxima-nova/proxima-nova-bold.woff2") format("woff2"),
        url("fonts/proxima-nova/proxima-nova-bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "proxima-nova";
    src: url("fonts/proxima-nova/proxima-nova-bold-italic.woff2") format("woff2"),
        url("fonts/proxima-nova/proxima-nova-bold-italic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "proxima-nova";
    src: url("fonts/proxima-nova/proxima-nova-light-italic.woff2") format("woff2"),
        url("fonts/proxima-nova/proxima-nova-light-italic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
}

/* Barlow */
@font-face {
    font-family: "barlow";
    src: url("fonts/barlow/barlow-thin.woff2") format("woff2"),
        url("fonts/barlow/barlow-thin.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "barlow";
    src: url("fonts/barlow/barlow-thin-italic.woff2") format("woff2"),
        url("fonts/barlow/barlow-thin-italic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "barlow";
    src: url("fonts/barlow/barlow-regular.woff2") format("woff2"),
        url("fonts/barlow/barlow-regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "barlow";
    src: url("fonts/barlow/barlow-regular-italic.woff2") format("woff2"),
        url("fonts/barlow/barlow-regular-italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "barlow";
    src: url("fonts/barlow/barlow-bold.woff2") format("woff2"),
        url("fonts/barlow/barlow-bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "barlow";
    src: url("fonts/barlow/barlow-bold-italic.woff2") format("woff2"),
        url("fonts/barlow/barlow-bold-italic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "barlow";
    src: url("fonts/barlow/barlow-black.woff2") format("woff2"),
        url("fonts/barlow/barlow-black.woff") format("woff");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "barlow";
    src: url("fonts/barlow/barlow-black-italic.woff2") format("woff2"),
        url("fonts/barlow/barlow-black-italic.woff") format("woff");
    font-weight: 800;
    font-style: italic;
}

/* Agile Estimator */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "barlow", sans-serif;
    line-height: 1.3em;
    color: #132e3e;
    margin: 0;
    font-weight: normal;
}

h1 {
    font-size: 28px;
    font-weight: normal;
}
h2 {
    font-size: 30px;
}
h3 {
    font-size: 25px;
    font-weight: 700;
}
h4 {
    font-size: 22px;
}
h5 {
    font-size: 12px;
}
h6 {
    font-size: 10px;
}

body,
body * {
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

#page {
    overflow-x: hidden;
    width: 100%;
    padding-bottom: 100px;
}

body,
p,
span,
ul,
ol,
span {
    font-family: "Helvetica Neue", "proxima-nova", sans-serif;
    line-height: 1.3em;
    margin: 0;
    font-size: 18px;
    font-weight: normal;
    color: #132e3e;
}

.grey-text {
    color: #cfd5d8;
}

.light-blue-text {
    color: #7b8f9a;
}

body {
    margin: 0 !important;
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

/** HEADER **/
.agile-header-container {
    box-shadow: 0px 0px 22px 1px rgb(0 0 0 / 17%);
    width: 100%;
    position: fixed;
    background-color: #FFF;
    z-index: 20;
}

.agile-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.agile-header-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin-right: auto;
}

.agile-header-title h1 {
   font-weight: 800;
   font-size: 28px;
   letter-spacing: 1.5px;
}

svg.augusto-logo {
    margin-right: 20px;
}

.agile-header-progress {
    margin-left: auto;
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

/*** HEADER PROGRESS BAR ***/
.quiz-results-progressbar {
    position: relative;
    width: 100%;
    height: 8px;
    background-color: #cfd5d8;
    margin-bottom: 10px;
    border-radius: 20px;
}

.progressbar-inner {
    position: relative;
    display: block;
    height: 100%;
    background-color: #11b557;
    border-radius: 20px;
}

.quiz-percent-zero.progressbar-inner-label {
    right: unset;
    left: 0;
}

.progressbar-inner-label {
    position: absolute;
    top: 14px;
    overflow: visible;
    right: 0;
    font-size: 15px;
}

/*** SLIDE TITLE AND PROGRESS ***/

.agile-estimator {
    padding: 60px 15px 20px 15px;
}

.estimator-note {
    text-align: center;
    color: #7b8f9a;
    width: 100%;
    max-width: 430px;
    margin: 0 auto;
    font-style: italic;
    padding-bottom: 40px;
}

.estimator-slider {
    margin: 50px 0px;
}

.estimator-confirmation {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    max-width: 445px;
}
.estimator-confirmation > * {
    padding-bottom: 30px;
}

.estimator-confirmation img {
    width: 100%;
    height: auto;
}

.estimator-progress {
    padding-bottom: 60px;
}

.estimator-progress-heading {
    display: flex;
    justify-content: center;
}

.progress-count {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.slide-number {
    display: flex;
    align-items: center;
}

.slide-number > *:not(:last-child) {
    margin-right: 10px;
}

.slide-title {
    text-align: left;
    margin-left: 30px;
}

.estimator-progress h2 {
    font-weight: 600;
}

/** SLIDE CONTENT **/

.estimator-instruction {
    text-align: center;
    font-size: 20px;
    width: 100%;
    max-width: 430px;
    margin: 0 auto;
    padding-top: 20px;
}

.final-page .estimator-progress {
    padding-bottom: 0;
}

.final-page .estimator-instruction {
    padding-top: 0;
}

.final-page .estimator-progress,
.final-page .estimator-instruction {
    margin-bottom: 40px;
}

.slider-questions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    margin-right: -3%;
}

.slide-question {
    width: 30%;
    margin-right: 3%;
    box-shadow: 4px 4px 20px 0px rgb(0 0 0 / 11%);
    border-radius: 15px;
    transition: all 0.3s ease;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border: 2px solid rgba(0,0,0,0); /* placeholder to prevent text wrapping when selected */
}

.slide-description {
    text-align: center;
    max-width: 800px;
    margin: auto;
}

.slide-question:not(.final-signup-form):hover {
    cursor: pointer;
    transform: translateY(-10px);
}

.slide-top {
    text-align: center;
    padding: 30px 10%;
    height: 100%;
}

.slide-top > * {
    margin-bottom: 20px;
}

svg.estimate-icon {
    width: 70px;
    height: 70px;
}

svg.estimate-icon.thank-you-icon {
    margin-bottom: 0;
}
.slide-top h3 {
    font-weight: 700;
}
.slide-top p {
    font-family: "Helvetica Neue", "proxima-nova",   sans-serif;
    color: #7b8f9a;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.slide-bottom {
    padding: 7% 13%;
    border-top: 1px solid #e0e0e0;
}

.slide-bottom-hidden {
   visibility: hidden;
}

.slide-bottom-estimates {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    justify-content: space-between;
}

.slide-bottom-title {
    font-size: 12px;
    letter-spacing: 1px;
}

.slide-bottom-estimate h6 {
    font-size: 10px;
    font-weight: 800;
    letter-spacing: 1px;
}

.slide-bottom-estimate h6 {
    margin-bottom: 3px;
}

@media screen and (max-width: 600px) {
    .slider-questions {
        display: block;
        margin-right: 0;
    }

    .slide-question {
        margin: auto;
    }

    .slider-questions > *:not(:last-child) {
        margin-bottom: 3%;
    }
}

/*** PAGINATION  / FOOTER ***/
.estimator-pagination {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;
    padding: 20px 50px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FFF;
    box-shadow: 0px 0px 22px 1px rgb(0 0 0 / 17%);


}

/* .estimator-pagination-previous {
    margin-left: 0;
    margin-right: auto;
}

.estimator-pagination-next {
    margin-right: 0;
    margin-left: auto;
} */

/** BUTTONS**/
.button-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.button-row > *:not(:last-child) {
    margin-right: 20px;
}

a.button,
input[type="submit"],
.button {
    background-color: #11b557;
    border-radius: 7px;
    padding: 8px 50px;
    text-decoration: none;
    color: #fff;
    font-family: "Proxima Nova", sans-serif;
    font-size: 20px;
    line-height: 1em;
    display: inline-flex;
    align-items: center;
    transition: all 0.2s ease;
}

a.button:hover,
input[type="submit"]:hover,
.button:hover {
    background-color: #21d56f;
    cursor: pointer;
}

.button .button-icon {
    fill: #fff;
    margin-left: 8px;
}

.button span {
    color: #fff;
    line-height: 1em;
    margin-bottom: -1px;
}

a.button.button-grey,
input[type="submit"].button-grey,
.button.button-grey {
    background-color: #eaeaea;
    color: #b5b5b5;
}

a.button.button-grey:hover,
input[type="submit"].button-grey:hover,
.button.button-grey:hover {
    background-color: #f4f4f4;
}

.button.button-grey span {
    color: #b5b5b5;
}

.button-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.button-icon.button-icon-next {
    margin-left: 8px;
}

/*** ICONS ***/
svg.green-fill {
    fill: #11b557;
}
svg.blue-fill {
    fill: #5d98ea;
}
svg.pink-fill {
    fill: #b511af;
}

/*** SIGNUP FORM ***/
.final-signup-form {
    background-image: url("assets/images/signup-form-bg.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px;
    border-radius: 15px;
    max-width: 675px;
}

form.signup-form {
    width: 100%;
    margin: 0 auto;
}

.signup-form .form-input {
    padding: 12px;
    width: 100%;
}

.signup-form input {
    border-radius: 5px;
    padding: 15px 10px;
    border: none;
    width: 100%;
}

.signup-form input:focus-visible {
    outline-offset: 0;
    border: none;
    outline: none;
}

input#email {
    width: 100%;
}

.final-signup-form .form-row {
    display: flex;
    flex-wrap: wrap;
}

.final-signup-form .form-row > * {
    flex: 1;
}

.signup-form-submit {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.signup-form-submit input.button {
    margin: 0;
    padding: 10px 50px;
    width: fit-content;
}

.slide-question.final-signup-form {
    margin-right: 0;
    width: 100%;
}

.form-error {
    background-color: #f3c2c2;
    padding: 7px 12px;
    line-height: 1em;
    text-align: center;
    color: #f60000;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #f60000;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.form-input-error input {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #f60000;
    border-bottom: 0;
}

.slide-question.question-selected {
    border: 2px solid #132e3e;
}

/* Overall Responsiveness */
@media screen and (max-width: 1000px) {
    .agile-header-progress {
        width: 40%
    }
}
@media screen and (max-width: 400px) {
    .agile-header-title h1 {
        font-size: 1.4rem;
    }
}
@media screen and (max-width: 800px) {
    .progress-count {
        flex-direction: column;
    }

    .slide-title {
        margin-left: 5%;
        margin-right: 5%;
    }

    .slide-question:last-of-type {
        margin-bottom: 20px;
    }

    .slide-question {
        width: 95%;
        align-self: center;
        box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 30%);
    }

    .slide-question:not(.final-signup-form):hover {
        transform: translateY(0px);
    }

    .slide-description {
        text-align: left;
        margin-left: 5%;
        margin-right: 5%;
    }

    .agile-header {
        padding-bottom: 30px;
    }

    .agile-header-title {
        margin: 0 auto 15px auto;
    }

    .agile-header-progress {
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
    }

    .agile-estimator {
        padding: 120px 0px 50px 0px;
    }

    .agile-estimator.final-page {
        padding-top: 0px;

    }

    .estimator-pagination {
        padding: 25px 25px;
    }

    .estimator-instruction {
        font-weight: 500;
    }


    .estimator-pagination .button {
        padding: 15px;
    }

    .final-page .estimator-progress {
        margin-bottom: 20px;
    }

    .slide-question.final-signup-form {
        padding: 40px 20px;
    }

    .button-row > *:not(:last-child) {
        margin-right: 0;
        margin-bottom: 14px;
    }

    .final-signup-form .form-row {
        display: block;
    }
}
